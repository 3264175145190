import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PublicIcon from "@mui/icons-material/Public";
import GithubIcon from "@mui/icons-material/GitHub";
import QRCode from "./QRCode";
import "../styles/contact.scss";
const Contact = (props) => {
  if (props.lang === "pl") {
    return (
      <section className={props.className}>
        <div className='row'>
          <div className='col'>
            <QRCode className='main main--qrqode w-100 print-only' />
          </div>
          <div className='col'>
            <div className='contact__item'>
              <a href='tel:+48509774949' className='contact__link'>
                <div className='contact__icon'>
                  <SmartphoneIcon />
                </div>
                +48 509-77-49-49
              </a>
            </div>
            <div className='contact__item'>
              <a
                href='mailto:radoslaw.stary@gmail.com'
                className='contact__link'>
                <div className='contact__icon'>
                  <MailIcon />
                </div>
                radoslaw.stary@gmail.com
              </a>
            </div>
            <div className='contact__item'>
              <div className='contact__link'>
                <div className='contact__icon'>
                  <LocationOnIcon />
                </div>
                Poznań
              </div>
            </div>
            <div className='contact__item'>
              <a href='https://github.com/radekstary' className='contact__link'>
                <div className='contact__icon'>
                  <GithubIcon />
                </div>
                github.com/radekstary
              </a>
            </div>
            <div className='contact__item'>
              <a href='https://cv.starprojects.pl' className='contact__link'>
                <div className='contact__icon'>
                  <PublicIcon />
                </div>
                cv.starprojects.pl
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
  if (props.lang === "en") {
    return (
      <section className={props.className}>
        <div className='row'>
          <div className='col'>
            <QRCode className='main main--qrqode w-100 print-only' />
          </div>
          <div className='col'>
            <div className='contact__item'>
              <a href='tel:+48509774949' className='contact__link'>
                <div className='contact__icon'>
                  <SmartphoneIcon />
                </div>
                +48 509-77-49-49
              </a>
            </div>
            <div className='contact__item'>
              <a
                href='mailto:radoslaw.stary@gmail.com'
                className='contact__link'>
                <div className='contact__icon'>
                  <MailIcon />
                </div>
                radoslaw.stary@gmail.com
              </a>
            </div>
            <div className='contact__item'>
              <div className='contact__link'>
                <div className='contact__icon'>
                  <LocationOnIcon />
                </div>
                Poznan, Poland
              </div>
            </div>
            <div className='contact__item'>
              <a href='https://github.com/radekstary' className='contact__link'>
                <div className='contact__icon'>
                  <GithubIcon />
                </div>
                github.com/radekstary
              </a>
            </div>
            <div className='contact__item'>
              <a href='https://cv.starprojects.pl' className='contact__link'>
                <div className='contact__icon'>
                  <PublicIcon />
                </div>
                cv.starprojects.pl
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default Contact;
