import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home.js";
import SliderItem from "./components/SliderItem.js";
import imgKongresZarzadcy from "./images/portfolio/kongres-zarzadcy.png";
import imgSpZimin from "./images/portfolio/spzimin.jpg";
import imgLiterkaPakietyPobran from "./images/portfolio/literka-pakiety_pobran.jpg";
import imgKongresTerapeutow from "./images/portfolio/kongres-terapeutow.jpg";
import imgKonferencjaKsiegowych from "./images/portfolio/konferencja-ksiegowych.jpg";
import imgZaufanyTerapeutaLP from "./images/portfolio/zaufany-terapeuta.jpg";

export default class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //       lang: localStorage.getItem('lang') || 'en',
  //   };
  // }

  // setLang(lang) {
  //   localStorage.setItem('lang', lang)
  //   this.setState({lang: lang})
  // }

  render() {
    return (
      <Router basename={''}>
        <Layout>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/cv' element={<Home />} />
            <Route
              exact
              path='/portfolio/spzimin'
              element={
                <SliderItem name='SP Zimin' imgSrc={imgSpZimin} 
              route='/portfolio/spzimin' />
              }
            />
            <Route
              exact
              path='/portfolio/kongreszarzadcy'
              element={<SliderItem name='Kongres Zarządcy' imgSrc={imgKongresZarzadcy} route='/portfolio/kongreszarzadcy' />}
            />
            <Route
              exact
              path='/portfolio/literka'
              element={<SliderItem name='Literka' imgSrc={imgLiterkaPakietyPobran} route='/portfolio/literka' />}
            />
            <Route
              exact
              path='/portfolio/kongresterapeutow'
              element={<SliderItem name='Kongres Terapeutów' imgSrc={imgKongresTerapeutow} route='/portfolio/kongresterapeutow' />}
            />
            <Route
              exact
              path='/portfolio/konferencjaksiegowych'
              element={<SliderItem name='Konferencja Księgowych' imgSrc={imgKonferencjaKsiegowych} route='/portfolio/konferencjaksiegowych' />}
            />
            <Route
              exact
              path='/portfolio/zaufanyterapeuta'
              element={<SliderItem name='Zaufany Terapeuta' imgSrc={imgZaufanyTerapeutaLP} route='/portfolio/zaufanyterapeuta' />}
            />
          </Routes>
        </Layout>
      </Router>
    );
  }
}


