import PropTypes from 'prop-types'
import React, { Component } from 'react'

export default class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func, 
}


  render() {
    return (
        <button onClick={()=>this.props.onClick()} className={this.props.className}>{this.props.children}</button> 
    )
  }
}
