import photo from '../images/r-stary.png';
import '../styles/me.scss';
const Me = () => {
    return (
        <div id="me">
            <img src={photo} alt="R. Stary" width="120" className="photo" />
            <div className="name">Radosław Stary</div>
            <div className="role">Frontend Developer</div>
        </div>
    )
}

export default Me;