import PropTypes from "prop-types";
import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Profile from "./Profile";
import Experience from "./Experience";
import Education from "./Education";
import Skills from "./Skills";
import Portfolio from "./Portfolio";
import Consent from "./Consent.js";
import "../styles/index.scss";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: localStorage.getItem("lang") || "en",
    };
  }
  static propTypes = {
    lang: PropTypes.string,
  };
  setLang(lang) {
    localStorage.setItem("lang", lang);
    this.setState({ lang: lang });
  }

  render() {
    return (
      <div id='cv'>
        <div className='page'>
          <Sidebar
            className='sidebar'
            setLang={(lang) => this.setLang(lang)}
            lang={this.state.lang}
          />
          <Profile
            className='main main--profile'
            lang={this.state.lang}
          />
          <Experience
            className='main main--experience'
            lang={this.state.lang}
          />
        </div>
        <div className='page'>
          <Education className='main main--education ' lang={this.state.lang} />
          <Skills
            className='main main--skills'
            lang={this.state.lang}
          />
          <Portfolio
            className='main main--portfolio'
            lang={this.state.lang}
          />
          <Consent className='main main--consent print-only'  lang={this.state.lang} />
        </div>
      </div>
    );
  }
}
