import qrcode from "../images/qrcode.png";

const QRCode = (props) => {
    return (
        <div className={props.className}>
            <div className="container text-center">
                <a href="https://cv.starprojects.pl"><img src={qrcode} alt="" width="120" /></a>
            </div>
        </div>
    )
}

export default QRCode;