import React from "react";
// import SliderNav from './Slider/SliderNav';
import Slider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PortfolioSlider = (props) => {
  // TODO: Custom Slider
  // const isActive = () => {

  // }
  return (
    <div>
      <Slider {...settings}>
        {props.children}
      </Slider>
    </div>
  );
};

export default PortfolioSlider;
