import React from "react";
import PortfolioSlider from "./Slider";
import SliderItem from "./SliderItem";
import homergy from "../images/portfolio/homergy.jpg";
import krokpokroku from "../images/portfolio/krokpokroku.jpg";
import kongresZarzadcy from "../images/portfolio/kongres-zarzadcy.png";
import literkaPakietyPobran from "../images/portfolio/literka-pakiety_pobran.jpg";
import kongresTerapeutow from "../images/portfolio/kongres-terapeutow.jpg";
import konferencjaKsiegowych from "../images/portfolio/konferencja-ksiegowych.jpg";
import zaufanyTerapeutaLP from "../images/portfolio/zaufany-terapeuta.jpg";
import spZimin from "../images/portfolio/spzimin.jpg";
import "../styles/portfolio.scss";
import PublicIcon from "@mui/icons-material/Public";

const Portfolio = (props) => {
  if (props.lang === "en") {
    return (
      <div className={props.className}>
        <div className='container noprint'>
          <div className='block__title'>
            <h2 className='block__title--text'>Sample projects</h2>
          </div>
          <PortfolioSlider
            title='Portfolio'
            titleUrl='web.starprojects.pl/portfolio'>
            <SliderItem
              route='/portfolio/homergy'
              name='Homergy'
              imgSrc={homergy}
              imgAlt=''
              itemHref='https://homergy.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>
                  Coding the website front in Angular based on the provided
                  graphic design,
                </li>
                <li>
                  Configuration and integration with Strapi headless CMS and
                  MySQL database
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/krokpokroku'
              name='Pracownia Krok po kroku'
              imgSrc={krokpokroku}
              imgAlt=''
              itemHref='https://pracownia-krokpokroku.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Website layout design,</li>
                <li>Frontend coding,</li>
                <li>Integration with Wordpress REST API (headless CMS)</li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/kongreszarzadcy'
              name='Kongres Zarządcy'
              imgSrc={kongresZarzadcy}
              imgAlt=''
              itemHref='https://kongreszarzadcy.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Website layout design,</li>
                <li>
                  Frontend coding
                  <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/literka'
              name='Literka.pl subpage'
              imgSrc={literkaPakietyPobran}
              imgAlt=''
              itemHref='https://www.literka.pl/pakiety-pobran'
              className='block__content--clearbg details'>
              <ul>
                <li>Website layout design,</li>
                <li>
                  Frontend coding
                  <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/kongresterapeutow'
              name='Therapists Congress'
              imgSrc={kongresTerapeutow}
              imgAlt=''
              itemHref='https://www.kongresterapeutow.pl/'
              className='block__content--clearbg details'>
              <ul>
                <li>Website layout design,</li>
                <li>
                  Frontend coding
                  <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/zaufanyterapeuta'
              name='Zaufany Terapeuta (Landing Page for therapists)'
              imgSrc={zaufanyTerapeutaLP}
              imgAlt=''
              itemHref='https://www.zaufanyterapeuta.eu/dla-terapeuty'
              className='block__content--clearbg details'>
              <ul>
                <li>Website layout design basing on key visual,</li>
                <li>
                  Frontend coding
                  <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/konferencjaksiegowych'
              name='Konferencja Księgowych'
              imgSrc={konferencjaKsiegowych}
              imgAlt=''
              itemHref='https://www.konferencja-ksiegowych.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Website layout design basing on key visual,</li>
                <li>
                  Frontend coding
                  <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/spzimin'
              name='ZSP Zimin'
              imgSrc={spZimin}
              imgAlt=''
              itemHref='https://spzimin.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Domain transfer, hosting config, </li>
                <li>Logo design ,</li>
                <li>
                  Refreshment of website's layout basing on free WP template
                </li>
              </ul>
            </SliderItem>
          </PortfolioSlider>
        </div>
        <div className='print-only'>
          <div className='block__title'>
            <h2 className='block__title--text'>Sample projects</h2>
          </div>
          <div className='contact__item'>
            <a
              href='https://homergy.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              homergy.pl
            </a>
          </div>

          <div className='contact__item'>
            <a
              href='https://spzimin.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              spzimin.pl
            </a>
          </div>

          <div className='contact__item'>
            <a
              href='https://pracownia-krokpokroku.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              pracownia-krokpokroku.pl
            </a>
          </div>

          <div className='contact__item'>
            <a
              href='https://realizacje.starprojects.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              realizacje.starprojects.pl
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (props.lang === "pl") {
    return (
      <div className={props.className}>
        <div className='container noprint'>
          <div className='block__title'>
            <h2 className='block__title--text'>Przykładowe realizacje</h2>
          </div>
          <PortfolioSlider
            title='Portfolio'
            titleUrl='web.starprojects.pl/portfolio'>
            <SliderItem
              route='/portfolio/homergy'
              name='Homergy'
              imgSrc={homergy}
              imgAlt=''
              itemHref='https://homergy.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>
                  Kodowanie frontu strony w Angular w oparciu o dostarczony
                  projekt graficzny,
                </li>
                <li>
                  Konfiguracja i integracja ze Strapi headless CMS oraz bazą
                  danych MySQL
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/krokpokroku'
              name='Pracownia Krok po kroku'
              imgSrc={krokpokroku}
              imgAlt=''
              itemHref='https://pracownia-krokpokroku.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Projekt graficzny layoutu strony,</li>
                <li>Kodowanie warstwy frontendowej</li>
                <li>Integracja z Wordpress REST API (headless CMS)</li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/kongreszarzadcy'
              name='Kongres Zarządcy'
              imgSrc={kongresZarzadcy}
              imgAlt=''
              itemHref='https://kongreszarzadcy.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Projekt graficzny layoutu strony,</li>
                <li>
                  Kodowanie warstwy frontendowej <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/literka'
              name='Literka.pl - podstrona z formularzem zakupu'
              imgSrc={literkaPakietyPobran}
              imgAlt=''
              itemHref='https://www.literka.pl/pakiety-pobran'
              className='block__content--clearbg details'>
              <ul>
                <li>Projekt graficzny layoutu strony,</li>
                <li>
                  Kodowanie warstwy frontendowej <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/kongresterapeutow'
              name='Kongres Terapeutów'
              imgSrc={kongresTerapeutow}
              imgAlt=''
              itemHref='https://www.kongresterapeutow.pl/'
              className='block__content--clearbg details'>
              <ul>
                <li>Projekt graficzny layoutu strony,</li>
                <li>
                  Kodowanie warstwy frontendowej <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/zaufanyterapeuta'
              name='Zaufany Terapeuta (Landing Page dla terapeutów)'
              imgSrc={zaufanyTerapeutaLP}
              imgAlt=''
              itemHref='https://www.zaufanyterapeuta.eu/dla-terapeuty'
              className='block__content--clearbg details'>
              <ul>
                <li>Projekt layoutu strony w oparciu o key visual,</li>
                <li>
                  Kodowanie warstwy frontendowej <div>(HTML, CSS/LESS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/konferencjaksiegowych'
              name='Konferencja Księgowych'
              imgSrc={konferencjaKsiegowych}
              imgAlt=''
              itemHref='https://www.konferencja-ksiegowych.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Projekt graficzny layoutu strony,</li>
                <li>
                  Kodowanie warstwy frontendowej <div>(HTML, CSS/LESS, JS)</div>
                </li>
              </ul>
            </SliderItem>
            <SliderItem
              route='/portfolio/spzimin'
              name='ZSP Zimin'
              imgSrc={spZimin}
              imgAlt=''
              itemHref='https://spzimin.pl'
              className='block__content--clearbg details'>
              <ul>
                <li>Transfer domeny, konfiguracja hostingu, </li>
                <li>Projekt logo</li>
                <li>
                  Odświeżenie layoutu strony w&nbsp;oparciu o&nbsp;darmowy
                  szablon WP
                </li>
              </ul>
            </SliderItem>
          </PortfolioSlider>
        </div>
        <div className='print-only'>
          <div className='block__title'>
            <h2 className='block__title--text'>Przykładowe realizacje</h2>
          </div>
          <div className='contact__item'>
            <a
              href='https://homergy.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              homergy.pl
            </a>
          </div>

          <div className='contact__item'>
            <a
              href='https://spzimin.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              spzimin.pl
            </a>
          </div>

          <div className='contact__item'>
            <a
              href='https://pracownia-krokpokroku.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              pracownia-krokpokroku.pl
            </a>
          </div>

          <div className='contact__item'>
            <a
              href='https://realizacje.starprojects.pl'
              className='contact__link'
              rel='noreferrer'
              target='_blank'>
              <div className='contact__icon'>
                <PublicIcon />
              </div>
              realizacje.starprojects.pl
            </a>
          </div>
        </div>
      </div>
    );
  }
};

export default Portfolio;
