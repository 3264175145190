const Education = (props) => {
  if (props.lang === "en") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title'>
            <h2 className='block__title--text'>Education</h2>
          </div>
          <section className='block block--clearbg'>
            <div className='block__content expanded'>
              <div className='content__header'>
                <div className='content__timeline'>10/2013 – 06/2015</div>
                <div className='content__title'>UAM in Poznań</div>
                <div className='content__subtitle'>
                  Master of Arts in Political Science
                </div>
              </div>
              <div className='content__details'>
                <div>
                  <span data-pl='Specjalność:'>Specialized in: </span>
                  <strong>Advertising and Promotion</strong>
                </div>
              </div>
            </div>
            <div className='block__content expanded'>
              <div className='content__header'>
                <div className='content__timeline'>10/2009 – 06/2013</div>
                <div className='content__title'>WSJO in Poznań</div>
                <div className='content__subtitle'>
                  Bachelor of Arts in European Studies
                </div>
              </div>
              <div className='content__details'>
                <div>
                  <span data-pl='Specjalność:'>Specialized in: </span>
                  <strong>European Enterprise</strong>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
  if (props.lang === "pl") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title'>
            <h2 className='block__title--text'>Wykształcenie</h2>
          </div>
          <section className='block block--clearbg'>
            <div className='block__content expanded'>
              <div className='content__header'>
                <div className='content__timeline'>10/2013 – 06/2015</div>
                <div className='content__title'>
                  Uniwersytet im. A. Mickiewicza w&nbsp;Poznaniu
                </div>
                <div className='content__subtitle'>Magister politologii</div>
              </div>
              <div className='content__details'>
                <div>
                  <span>Specjalność: </span>
                  <strong>reklama i promocja</strong>
                </div>
              </div>
            </div>
            <div className='block__content expanded'>
              <div className='content__header'>
                <div className='content__timeline'>10/2009 – 06/2013</div>
                <div
                  className='content__title'>
                  Wyższa Szkoła Języków Obcych im.&nbsp;S.&nbsp;Lindego w&nbsp;Poznaniu
                </div>
                <div
                  className='content__subtitle'
                  >
                  Licencjat europeistyki
                </div>
              </div>
              <div className='content__details'>
                <div>
                  <span>Specjalność: </span>
                  <strong>
				  przedsiębiorstwo europejskie
                  </strong>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
};

export default Education;
