import PrintIcon from "@mui/icons-material/Print";
import polishFlag from "../images/polish.png";
import englishFlag from "../images/english.png";
import Button from "./Button";
import Me from "./Me";
import Contact from "./Contact";
import QRCode from "./QRCode";
import "../styles/sidebar.scss";
const Sidebar = (props) => {
  return (
    <div className='sidebar'>
      <div className='buttons w-100 noprint'>
        <Button
          className='printer noprint'
          onClick={() => {
            window.print();
            return false;
          }}>
          <PrintIcon />
        </Button>
        <div className='language-selection noprint'>
          <Button
            onClick={() => props.setLang("en")}
            className={props.lang === "en" ? "active" : undefined}>
            <img src={englishFlag} id='en' alt='EN' className='language' />
          </Button>
          <Button
            onClick={() => props.setLang("pl")}
            className={props.lang === "pl" ? "active" : undefined}>
            <img src={polishFlag} id='pl' alt='PL' className='language' />
          </Button>
        </div>
      </div>
      <Me />
      <Contact className='sidebar__contact' lang={props.lang} />

      {/* <QRCode className='main main--qrqode w-100 print-only' /> */}
    </div>
  );
};

export default Sidebar;
