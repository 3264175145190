import "../styles/profile.scss";
const Profile = (props) => {
  if (props.lang === "en") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title noprint'>
            <h2 className='block__title--text'>About me</h2>
          </div>
          <section className='block'>
            <div className='block__content block__content--clearbg details'>
              <div className='print-only'>
                <p className='details__paragraph'>
                  <b>Goals:</b> improve Angular and React, learn Vue and Svelte,
                  master C# and Python..
                </p>
                <p className='details__paragraph'>
                  <b>Keys:</b> JS and CSS design patterns, mobile
                  first & pixel perfect approach.
                </p>
                <p className='details__paragraph'>
                  I am <b>open to criticism</b> and new ideas. I&nbsp;can{" "}
                  <b>easily acquire knowledge</b> and{" "}
                  <b>share my experience</b>.
                </p>
              </div>
              <div className='noprint'>
                <p className='details__paragraph '>
                  I'm a team player, loving to travel, intrested in new
                  technologies, sports and politics.
                </p>
                <p className='details__paragraph '>
                  I like to spend my free time on learning programming. One of
                  my favourite tools is{" "}
                  <a
                    href='https://www.codewars.com/users/radekstary'
                    target='blank'
                    rel='noopener nofollow'>
                    CodeWars
                  </a>
                  . My personal goal - except constant improvement of knowledge
                  related to JS/TS - is to master also C# and Python.
                </p>
                <p className='details__paragraph'>
                  At work I tend to leave nothing to chance. When designing
                  responsive layouts I&nbsp;always follow the{" "}
                  <em>mobile first</em> rule, using the <em>pixel perfect</em>{" "}
                  approach. I&nbsp;know and follow the CSS and JS design
                  patterns.
                </p>
                <p className='details__paragraph'>
                  I am always open to criticism, willing to take advice and
                  eager to learn. I can assimilate new ideas efficiently and
                  easily share my experience with teammates.
                </p>
                <p className='details__paragraph'>
                  You can find me on LinkedIn: <br />
                  <a
                    href='https://www.linkedin.com/in/radoslaw-stary'
                    target='_blank'
                    rel='noreferrer'>
                    linkedin.com/in/radoslaw-stary
                  </a>
                  .
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  if (props.lang === "pl") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title noprint'>
            <h2 className='block__title--text'>O mnie</h2>
          </div>
          <section className='block'>
            <div className='block__content block__content--clearbg details'>
              <div className='print-only'>
                <p className='details__paragraph'>
                  <b>Cele:</b> doskonalić Angular i&nbsp;React, poznać Vue oraz
                  Svelte, opanować C# i&nbsp;Python.
                </p>
                <p className='details__paragraph'>
                  <b>Czym się kieruję?</b> wzorce projektowe JS i CSS, podejście
                  mobile first, pixel perfect.
                </p>
                <p className='details__paragraph'>
                  Jestem <b>otwarty na krytykę</b> i&nbsp;nowe pomysły.
                  Z&nbsp;łatwością <b>przyswajam wiedzę</b> oraz{" "}
                  <b>dzielę się doświadczeniem</b>.
                </p>
              </div>
              <div className='noprint'>
                <p className='details__paragraph'>
                  Jestem graczem zespołowym, który uwielbia podróżować,
                  interesuje się nowymi technologiami, sportem i polityką.
                </p>
                <p className='details__paragraph noprint'>
                  Wolny czas lubię spędzać na nauce programowania. Korzystam
                  m.in. z{" "}
                  <a
                    href='https://www.codewars.com/users/radekstary'
                    target='blank'
                    rel='noopener nofollow'>
                    CodeWars
                  </a>
                  {". "}
                  Moim najbliższym celem - poza doskonaleniem wiedzy związanej
                  z&nbsp;JavaScript/TypeScript - jest opanowanie także języków:
                  C# oraz Python.
                </p>
                <p className='details__paragraph'>
                  W mojej pracy staram się nie pozostawiać niczego przypadkowi.
                  Projektując responsywne strony i aplikacje, zawsze trzymam się
                  zasady <i>mobile first</i> i&nbsp;podejścia{" "}
                  <i>pixel&nbsp;perfect</i>. Znam i&nbsp;stosuję w praktyce
                  wzorce projektowe CSS i JS.
                </p>
                <p className='details__paragraph'>
                  Jestem otwarty na krytykę i&nbsp;zawsze chętnie przyjmuję
                  porady współpracowników. Potrafię sprawnie przyswajać nowe
                  pomysły i&nbsp;z&nbsp;łatwością dzielę się doświadczeniem
                  z&nbsp;zespołem.
                </p>
                <p className='details__paragraph noprint'>
                  Możesz znaleźć mnie na portalu LinkedIn: <br />
                  <a
                    href='https://www.linkedin.com/in/radoslaw-stary'
                    target='_blank'
                    rel='noreferrer'>
                    linkedin.com/in/radoslaw-stary
                  </a>
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
};

export default Profile;
