const Skills = (props) => {
  if (props.lang === "en") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title'>
            <h2
              className='block__title--text'>
              Tools and skills
            </h2>
          </div>
          <section className='block block--clearbg'>
            <div className='block__content block__content--clearbg details'>
              <div className='badge'>JavaScript/TypeScript</div>
              <div className='badge'>Angular</div>
              <div className='badge'>React</div>
              <div className='badge'>jQuery</div>
              <br />
              <div className='badge'>CSS</div>
              <div className='badge'>SCSS/SASS/LESS</div>
              <div className='badge'>HTML</div>
              <div className='badge'>Bootstrap</div>
              <br />
              <div className='badge'>Owl Carousel</div>
              <div className='badge'>Chart.js</div>
              <div className='badge'>Web Animations API</div>
              <br />
              <div className='badge'>NPM</div>
              <div className='badge'>Tortoise SVN</div>
              <div className='badge'>GIT</div>
              <div className='badge'>Webpack</div>
              <div className='badge'>Gulp</div>
              <div className='badge'>REST API</div>
              <div className='badge'>GraphQL</div>
              <div className='badge'>Material UI</div>
              <div className='badge'>WordPress</div>
              <div className='badge'>PHP</div>
              <div className='badge'>Photoshop</div>
              <div className='badge'>Illustrator</div>
              <div className='badge'>Dreamweaver</div>
              <div className='badge'>Adobe Xd</div>
              <div className='badge'>Figma</div>
              <div className='badge'>MongoDB</div>
              <div className='badge'>MySQL</div>
              <div className='badge'>Docker</div>
              <div className='badge'>Kubernetes</div>
              <br />
              <div className='badge'>RWD</div>
              <div className='badge'>DRY</div>
              <div className='badge'>SOLID</div>
              <div className='badge'>KISS</div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  if (props.lang === "pl") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title'>
            <h2
              className='block__title--text'>
              Technologie i umiejętności
            </h2>
          </div>
          <section className='block block--clearbg'>
            <div className='block__content block__content--clearbg details'>
              <div className='badge'>JavaScript/TypeScript</div>
              <div className='badge'>Angular</div>
              <div className='badge'>React</div>
              <div className='badge'>jQuery</div>
              <br />
              <div className='badge'>CSS</div>
              <div className='badge'>SCSS/SASS/LESS</div>
              <div className='badge'>HTML</div>
              <div className='badge'>Bootstrap</div>
              <br />
              <div className='badge'>Owl Carousel</div>
              <div className='badge'>Chart.js</div>
              <div className='badge'>Web Animations API</div>
              <br />
              <div className='badge'>NPM</div>
              <div className='badge'>Tortoise SVN</div>
              <div className='badge'>GIT</div>
              <div className='badge'>Webpack</div>
              <div className='badge'>Gulp</div>
              <div className='badge'>REST API</div>
              <div className='badge'>GraphQL</div>
              <div className='badge'>Material UI</div>
              <div className='badge'>WordPress</div>
              <div className='badge'>PHP</div>
              <div className='badge'>Photoshop</div>
              <div className='badge'>Illustrator</div>
              <div className='badge'>Dreamweaver</div>
              <div className='badge'>Adobe Xd</div>
              <div className='badge'>Figma</div>
              <div className='badge'>MongoDB</div>
              <div className='badge'>MySQL</div>
              <div className='badge'>Docker</div>
              <div className='badge'>Kubernetes</div>
              <br />
              <div className='badge'>RWD</div>
              <div className='badge'>DRY</div>
              <div className='badge'>SOLID</div>
              <div className='badge'>KISS</div>
            </div>
          </section>
        </div>
      </div>
    );
  }
};

export default Skills;
