import StatusLight from "./StatusLight";
import "../styles/experience.scss";
const Experience = (props) => {
  if (props.lang === "en") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title'>
            <h2 className='block__title--text'>
              Professional experience
              <br />
              <span className='block__title--smaller'>
                (only programming / IT related)
              </span>
            </h2>
          </div>
          <section className='block block--clearbg'>
            <div className='block__content clickable expanded'>
              <div className='content__header'>
                <div className='content__timeline'>02/2023 – present</div>
                <div className='content__environment'>remote</div>
                <StatusLight className='content__status' isActive={true} />
                <div className='content__title'>
                  Frontend Developer (Angular)
                </div>
                <div className='content__subtitle'>
                  Asseco Business Solutions
                </div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>implementing UI using DevExpress components,</li>
                  <li>
                    cooperation with the UX department and testing department,
                  </li>
                  <li>
                    integrated the front-end of the ERP application with
                    printing devices,
                  </li>
                  <li>
                    implemented support for printing receipts on fiscal printers
                    on the frontend,
                  </li>
                  <li>
                    created a visual editor of product and address label
                    templates with drag & drop function.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className='block__content clickable expanded'>
              <div className='content__header'>
                <div className='content__timeline'>05/2020 – 01/2023</div>
                <div className='content__environment'>hybrid</div>
                <StatusLight className='content__status' isActive={false} />
                <div className='content__title'>
                  Online marketing designer (frontend role)
                </div>
                <div className='content__subtitle'>Forum Media Polska</div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    adding/updating content and maintaining company's CMS-based
                    websites,
                  </li>
                  <li>code testing and troubleshooting,</li>
                  <li>
                    designed and coded from scratch more than 30 landing pages
                    and a dozen e-mail marketing templates,
                  </li>
                  <li>helped in training two new team members.</li>
                </ul>
              </div>
            </div>
            {/* <div className='block__content clickable'>
              <div className='content__header'>
                <div className='content__timeline'>10/2019 – 04/2020</div>
                <div className='content__title'>Modelling technician</div>
                <div className='content__subtitle'>Sivantos</div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    structuring 3D models of hearing aids in 3Shape
                    ShellDesigner tool
                  </li>
                  <li>
                    resolving issues reported by customer support and production
                    line workers
                  </li>
                </ul>
              </div>
            </div> */}
            <div className='block__content clickable expanded'>
              <div className='content__header'>
                <div className='content__timeline'>2017 – present</div>
                <div className='content__environment'>remote</div>
                <StatusLight className='content__status' isActive={true} />
                <div className='content__title'>Freelancing</div>
                <div className='content__subtitle'>Self-employment</div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    designing, coding and administrating websites (simple
                    one-pages and landing pages, complex CMS-based portals)
                  </li>
                  <li>designing simple 2D graphics (logos, photo editing)</li>
                  <li>providing office administration services</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }

  if (props.lang === "pl") {
    return (
      <div className={props.className}>
        <div className='container'>
          <div className='block__title'>
            <h2 className='block__title--text'>
              Profesjonalne doświadczenie <br />
              <span className='block__title--smaller'>
                (tylko związane z programowaniem / IT)
              </span>
            </h2>
          </div>
          <section className='block block--clearbg'>
            <div className='block__content clickable expanded'>
              <div className='content__header'>
                <div className='content__timeline'>02/2023 – present</div>
                <div className='content__environment'>zdalnie</div>
                <StatusLight className='content__status' isActive={true} />
                <div className='content__title'>
                  Programista Frontend (Angular)
                </div>
                <div className='content__subtitle'>
                  Asseco Business Solutions S.A.
                </div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    implementowanie interfejsu użytkownika przy użyciu
                    komponentów DevExpress,
                  </li>
                  <li>współpraca z działem UX i działem testów,</li>
                  <li>
                    zintegrowałem frontend aplikacji ERP z urządzeniami
                    drukującymi,
                  </li>
                  <li>
                    zaimplementowałem po stronie frontendu obsługę drukowania
                    paragonów na drukarkach fiskalnych,
                  </li>
                  <li>
                    stworzyłem wizualny edytor szablonów etykiet towarowych i
                    adresowych z funkcją drag & drop.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className='block__content clickable expanded'>
              <div className='content__header'>
                <div className='content__timeline'>05/2020 – 01/2023</div>
                <div className='content__environment'>hybrydowo</div>
                <StatusLight className='content__status' isActive={false} />
                <div className='content__title'>
                  Online marketing designer (frontend)
                </div>
                <div className='content__subtitle'>Forum Media Polska</div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    aktualizowanie i dodawanie nowych treści oraz
                    administrowanie witrynami internetowymi firmy,
                  </li>
                  <li>pomoc w testowaniu i rozwiązywaniu problemów,</li>
                  <li>
                    zaprojektowałem i zakodowałem od podstaw 30+ stron
                    internetowych typu 'landing page' oraz kilkanaście szablonów
                    mailingowych
                  </li>
                  <li>pomogłem we wdrożeniu dwóch nowych członków zespołu.</li>
                </ul>
              </div>
            </div>
            {/* <div className='block__content clickable'>
              <div className='content__header'>
                <div className='content__timeline'>10/2019 – 04/2020</div>
                <div className='content__title'>Technik modelingu</div>
                <div className='content__subtitle'>Sivantos</div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    projektowanie trójwymiarowych modeli aparatów słuchowych w
                    programie 3shape ShellDesigner
                  </li>
                  <li>
                    naprawa usterek zgłaszanych przez obsługę klienta i
                    pracowników linii produkcyjnej
                  </li>
                </ul>
              </div>
            </div> */}
            <div className='block__content clickable expanded'>
              <div className='content__header'>
                <div className='content__timeline'>2017 – obecnie</div>
                <div className='content__environment'>zdalnie</div>
                <StatusLight className='content__status' isActive={true} />
                <div className='content__title'>Samozatrudnienie</div>
                <div className='content__subtitle'>
                  StarProjects Radosław Stary
                </div>
                <div className='content__toggle'></div>
              </div>
              <div className='content__details'>
                <ul className='content__details--list'>
                  <li>
                    projektowanie, kodowanie i administrowanie stronami
                    internetowymi
                  </li>
                  <li>grafika 2D (logo, edycja zdjęć)</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
};

export default Experience;
